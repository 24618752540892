<template>
  <div>
    <ValidationObserver ref="form">
      <div class="columns">
        <div class="column">
          <ValidationProvider name="Informare" v-slot="{ errors }">
            <b-field :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
              <b-checkbox
                @input="changeIsNotice()"
                v-model="businessLetter.isNotice"
                :disabled="isDisabled"
                >Informare</b-checkbox
              >
            </b-field>
          </ValidationProvider>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <ValidationProvider name="Emitent" v-slot="{ errors }">
            <b-field
              :type="errors[0] ? 'is-danger' : ''"
              label="Emitent"
              :message="errors[0]"
            >
              <b-input v-model="businessLetter.issuer" :disabled="isDisabled" />
            </b-field>
          </ValidationProvider>
        </div>
        <div class="column">
          <ValidationProvider
            name="Nr. Inreg. extern"
            rules="required"
            v-slot="{ errors }"
          >
            <b-field
              :type="errors[0] ? 'is-danger' : ''"
              label="Nr. Inreg. extern"
              :message="errors[0]"
            >
              <b-input
                v-model="businessLetter.externalRegistrationNumber"
                required
                :disabled="isDisabled"
              />
            </b-field>
          </ValidationProvider>
        </div>
        <div class="column">
          <ValidationProvider
            name="Data Inreg. extern"
            rules="required"
            v-slot="{ errors }"
          >
            <b-field
              :type="errors[0] ? 'is-danger' : ''"
              label="Data Inreg. extern"
              :message="errors[0]"
            >
              <b-datepicker
                :date-parser="parser"
                v-model="businessLetter.externalRegistrationDate"
                locale="ro"
                :disabled="isDisabled"
              >
              </b-datepicker>
            </b-field>
          </ValidationProvider>
        </div>
        <div class="column">
          <ValidationProvider name="Nr. Inreg. intern" v-slot="{ errors }">
            <b-field
              :type="errors[0] ? 'is-danger' : ''"
              label="Nr. Inreg. intern"
              :message="errors[0]"
            >
              <b-input
                v-model="businessLetter.internalRegistrationNumber"
                :disabled="isDisabled"
              />
            </b-field>
          </ValidationProvider>
        </div>
        <div class="column">
          <div class="field">
            <label class="label">Data Inreg. intern</label>
            <div class="control">
              <b-datepicker
                :date-parser="parser"
                v-model="businessLetter.internalRegistrationDate"
                locale="ro"
                :unselectable-days-of-week="[0, 6]"
                :disabled="isDisabled"
              >
              </b-datepicker>
            </div>
          </div>
        </div>
        <div class="column is-2">
          <div class="field">
            <ValidationProvider
              name="Departament"
              rules="required"
              v-slot="{ errors }"
            >
              <b-field
                :type="errors[0] ? 'is-danger' : ''"
                label="Departament"
                :message="errors[0]"
              >
                <b-dropdown
                  v-model="departmentName"
                  multiple
                  aria-role="list"
                  :disabled="isDisabled"
                >
                  <template #trigger>
                    <b-button type="is-primary" icon-right="menu-down">
                      Selectat ({{ departmentName.length }})
                    </b-button>
                  </template>

                  <b-dropdown-item value="Primar" aria-role="listitem">
                    <span>Primar</span>
                  </b-dropdown-item>

                  <b-dropdown-item value="Viceprimar" aria-role="listitem">
                    <span>Viceprimar</span>
                  </b-dropdown-item>

                  <b-dropdown-item value="Secretar" aria-role="listitem">
                    <span>Secretar</span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    value="Asistență socială"
                    aria-role="listitem"
                  >
                    <span>Asistență socială</span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    value="Impozite și taxe"
                    aria-role="listitem"
                  >
                    <span>Impozite și taxe</span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    value="Contabilitate și buget"
                    aria-role="listitem"
                  >
                    <span>Contabilitate și buget</span>
                  </b-dropdown-item>

                  <b-dropdown-item value="Agricol" aria-role="listitem">
                    <span>Agricol</span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    value="Relații cu publicul"
                    aria-role="listitem"
                  >
                    <span>Relații cu publicul</span>
                  </b-dropdown-item>

                  <b-dropdown-item value="Cultură" aria-role="listitem">
                    <span>Cultură</span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    value="Asistență comunitară"
                    aria-role="listitem"
                  >
                    <span>Asistență comunitară</span>
                  </b-dropdown-item>

                  <b-dropdown-item value="Urbanism" aria-role="listitem">
                    <span>Urbanism</span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    value="Achiziții publice"
                    aria-role="listitem"
                  >
                    <span>Achiziții publice</span>
                  </b-dropdown-item>

                  <b-dropdown-item value="Stare civilă" aria-role="listitem">
                    <span>Stare civilă</span>
                  </b-dropdown-item>

                  <b-dropdown-item value="Administrativ" aria-role="listitem">
                    <span>Administrativ</span>
                  </b-dropdown-item>

                  <b-dropdown-item value="S.V.S.U" aria-role="listitem">
                    <span>S.V.S.U</span>
                  </b-dropdown-item>
                </b-dropdown>
              </b-field>
            </ValidationProvider>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-3">
          <ValidationProvider
            name="Continut (pe scurt)"
            rules="required"
            v-slot="{ errors }"
          >
            <b-field
              :type="errors[0] ? 'is-danger' : ''"
              label="Continut (pe scurt)"
              :message="errors[0]"
            >
              <b-input
                v-model="businessLetter.description"
                type="text"
                required
                :disabled="isDisabled"
              />
            </b-field>
          </ValidationProvider>
        </div>
        <div class="column is-1">
          <ValidationProvider
            name="Nr.zile pentru solutionare"
            v-slot="{ errors }"
          >
            <b-field
              :type="errors[0] ? 'is-danger' : ''"
              label="Nr.zile sol."
              :message="errors[0]"
            >
              <b-input
                v-model="businessLetter.numberOfDaysToRespond"
                step="1"
                min="0"
                max="10000000"
                type="number"
                @input="changeNumberOfDaysToRespond"
                :disabled="isDisabled || isNotice"
              />
            </b-field>
          </ValidationProvider>
        </div>
        <div class="column is-2">
          <div class="field">
            <label class="label">Data limita solutionare</label>
            <div class="control">
              <b-datepicker
                :date-parser="parser"
                v-model="businessLetter.deadline"
                @input="deadlineSelected()"
                locale="ro"
                :unselectable-days-of-week="[0, 6]"
                :disabled="isDisabled || isNotice"
              >
              </b-datepicker>
            </div>
          </div>
        </div>
        <div class="column is-4">
          <ValidationProvider name="Solutionare (pe scurt)" v-slot="{ errors }">
            <b-field
              :type="errors[0] ? 'is-danger' : ''"
              label="Solutionare (pe scurt)"
              :message="errors[0]"
            >
              <b-input v-model="businessLetter.responseDescription" />
            </b-field>
          </ValidationProvider>
        </div>
        <div class="column is-2">
          <div class="field">
            <label class="label">Data solutionare</label>
            <div class="control">
              <b-datepicker
                :date-parser="parser"
                v-model="businessLetter.responseDate"
                locale="ro"
                :unselectable-days-of-week="[0, 6]"
              >
              </b-datepicker>
            </div>
          </div>
        </div>
      </div>
    </ValidationObserver>
    <div class="columns">
      <div class="column">
        <b-field grouped>
          <div class="control">
            <b-button
              type="is-primary"
              native-type="submit"
              @click="handleAddBusinessLetter"
              >Salvare</b-button
            >
          </div>
          <div class="control">
            <b-button type="is-danger is-outlined" @click="cancel()"
              >Anulare</b-button
            >
          </div>
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
import { rawObject } from "@/helpers/utils";
import { pathOr } from "ramda";
import BusinessLetters from "@/services/businessLetters.service";
import moment from "moment";

export default {
  name: "BusinessLettersAdd",
  watch: {
    isNotice: {
      immediate: true,
      handler(newVal) {
        if (newVal === true || this.isNoticeComputed) {
          return (this.isNotice = "disabled");
        } else {
          return null;
        }
      },
    },
  },
  methods: {
    changeIsNotice() {
      this.isNotice = this.businessLetter.isNotice;
    },
    deadlineSelected() {
      var a = moment(this.businessLetter.deadline).startOf("day");
      var b = moment(this.businessLetter.internalRegistrationDate).startOf(
        "day"
      );

      this.businessLetter.numberOfDaysToRespond = moment
        .duration(a.diff(b))
        .asDays();
    },
    changeNumberOfDaysToRespond(value) {
      this.businessLetter.deadline = new Date(
        moment(this.businessLetter.internalRegistrationDate, "DD-MM-YYYY").add(
          value,
          "days"
        )
      );
    },
    parser(d) {
      return new Date(Date.parse(d));
    },
    upload(file) {
      this.$emit("input", file);
    },
    deleteDropFile() {
      // this.dropFiles.splice();
    },
    handleAddBusinessLetter() {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          return;
        }
        this.businessLetter.username = this.$auth.user.email;
        this.businessLetter.department = this.departmentName.join();
        this.businessLetter.internalRegistrationDate = new Date(
          this.businessLetter.internalRegistrationDate.getTime() -
            this.businessLetter.internalRegistrationDate.getTimezoneOffset() *
              60000
        ).toJSON();
        this.businessLetter.externalRegistrationDate = new Date(
          this.businessLetter.externalRegistrationDate.getTime() -
            this.businessLetter.externalRegistrationDate.getTimezoneOffset() *
              60000
        ).toJSON();
        this.businessLetter.deadline = new Date(
          this.businessLetter.deadline.getTime() -
            this.businessLetter.deadline.getTimezoneOffset() * 60000
        ).toJSON();
        if (this.businessLetter.responseDate) {
          this.businessLetter.responseDate = new Date(
            this.businessLetter.responseDate.getTime() -
              this.businessLetter.responseDate.getTimezoneOffset() * 60000
          ).toJSON();
        }

        if (this.editMode) {
          this.$emit(
            "handleEditBusinessLetter",
            rawObject(this.businessLetter)
          );
        } else {
          this.$emit("handleAddBusinessLetter", rawObject(this.businessLetter));
        }
      });
    },
    async getBusinessLetter(id) {
      const { handleGetBusinessLetter } = BusinessLetters;
      const response = await handleGetBusinessLetter(id);
      const { data } = response;
      return data;
    },
    cancel() {
      this.$router.push({ path: "/BusinessLetters" });
    },
  },
  data() {
    return {
      isAdmin: this.$auth.user.email === "registratura@primarie.online",
      editMode: false,
      id: 0,
      isNotice: false,
      departmentName: [],
      businessLetter: {
        isNotice: false,
        issuer: null,
        externalRegistrationNumber: null,
        externalRegistrationDate: null,
        internalRegistrationNumber: null,
        internalRegistrationDate: null,
        department: null,
        description: null,
        numberOfDaysToRespond: 0,
        deadline: null,
        responseDescription: null,
        responseDate: null,
        username: null,
      },
    };
  },
  computed: {
    isNoticeComputed() {
      return this.businessLetter.isNotice;
    },
    isDisabled() {
      if (this.isAdmin) {
        return null;
      }
      return "disabled";
    },
  },
  created() {
    const id = parseInt(pathOr(null, ["params", "id"], this?.$route));
    this.id = id;
    if (this.id) {
      this.editMode = true;
      this.getBusinessLetter(id).then((response) => {
        this.businessLetter = response;
        this.businessLetter.externalRegistrationDate = new Date(
          this.businessLetter.externalRegistrationDate
        );
        this.businessLetter.internalRegistrationDate = new Date(
          this.businessLetter.internalRegistrationDate
        );
        this.businessLetter.deadline = new Date(this.businessLetter.deadline);
        if (this.businessLetter.responseDate) {
          this.businessLetter.responseDate = new Date(
            this.businessLetter.responseDate
          );
        }
        this.isNotice = this.businessLetter.isNotice;
        this.departmentName = this.businessLetter.department.split(",");
      });
    } else {
      this.businessLetter.externalRegistrationDate = new Date();
      this.businessLetter.internalRegistrationDate = new Date();
      this.businessLetter.deadline = new Date(
        moment(this.businessLetter.internalRegistrationDate, "DD-MM-YYYY").add(
          this.businessLetter.numberOfDaysToRespond,
          "days"
        )
      );
    }
  },
};
</script>
