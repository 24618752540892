<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <section class="section is-main-section">
      <card-component
        :title="formCardTitle"
        icon="file-edit"
        class="tile is-child"
      >
        <businessLetters-add
          @handleAddBusinessLetter="handleAddBusinessLetter"
          @handleEditBusinessLetter="handleEditBusinessLetter"
        />
      </card-component>
    </section>
  </div>
</template>

<script>
import { pathOr } from "ramda";
import BusinessLetters from "@/services/businessLetters.service";
import TitleBar from "@/components/TitleBar";
import CardComponent from "@/components/CardComponent";
import BusinessLettersAdd from "../components/businessLetters/businessLetters-add.vue";

export default {
  name: "BusinessLetter",
  components: { TitleBar, CardComponent, BusinessLettersAdd },
  data() {
    return {
      editMode: false,
    };
  },
  methods: {
    async handleAddBusinessLetter(businessLetter) {
      const { handleAddBusinessLetter } = BusinessLetters;
      const response = await handleAddBusinessLetter(businessLetter);
      const { data, status } = response;
      if (status == "200") {
        this.$router.push({ path: "/BusinessLetters" });
      } else {
      }
    },
    async handleEditBusinessLetter(businessLetter) {
      const { handleEditBusinessLetter } = BusinessLetters;
      const response = await handleEditBusinessLetter(businessLetter);
      this.$router.push({ path: "/BusinessLetters" });
    },
  },
  computed: {
    titleStack() {
      if (this.editMode) {
        return ["Documente", "Editare document"];
      } else {
        return ["Documente", "Adaugare document"];
      }
    },
    formCardTitle() {
      if (this.editMode) {
        return "Editare document";
      } else {
        return "Adaugare document";
      }
    },
  },
  created() {
    const id = parseInt(pathOr(null, ["params", "id"], this?.$route));
    if (id) {
      this.editMode = true;
    } else {
      // this.announcement.date = new Date();
    }
  },
};
</script>
